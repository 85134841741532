import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, ViewChild, ViewEncapsulation, AfterViewInit, SimpleChanges } from '@angular/core';
import { Request, StatementReplyHistoryPerDeptRequest, StatementReplyHistoryPerDeptResponse } from '@data/model';
import { IAttachment } from '@spga-shared/components';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { RealEstateRequestAttachmentRepository, RealEstateRequestRepository } from '@data/repository';
import { Router } from '@angular/router';
import { RequestStatus, StatementType,  statementTypeOptions } from '@proxy/enums';
import { RealEstateRequestStatementsRepository } from '@data/repository/real-estate-request-statements.repository';
import { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { AppAuthService } from '@spga-shared/providers';
import { RequestPreviewTabs, RolesOptions } from '@data/common/enum';
import { StatementReplyHistoryForAllDeptResponse } from '@proxy/real-estate-ownership-requests-statements';
import { ToasterService } from '@abp/ng.theme.shared';
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-request-details',
  templateUrl: './request-details.component.html',
  styleUrls: ['./request-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RequestDetailsComponent implements OnInit, OnChanges {
  isLoading: boolean = true;
  isPreWithApprovedRequest : boolean = false;
  @ViewChild('shadowBeneficiaryDetails', { static: false }) shadowBeneficiaryDetails!: ElementRef;

  @Input() canAccessRequestBeneficiaryIdentificationNumber: boolean = false;
  @Input() canAccessRequestBeneficiaryName : boolean = false;
  @Input() canAccessRequesBeneficiaryBirthdate : boolean = false;
  @Input() canAccessRequestBeneficiaryEmail : boolean = false;
  @Input() canAccessRequestBeneficiaryMobileNumber : boolean = false;

  @Input() canAccessRequestOwnerName : boolean = false;
  @Input() canAccessRequestOwnerNationality : boolean = false;
  @Input() canAccessRequestOwnerIdentificationType : boolean = false;
  @Input() canAccessRequestOwnerIdentificationNumber : boolean = false;
  @Input() canAccessRequestOwnerIssueDate : boolean = false;
  @Input() canAccessRequestOwnerExpiryDate : boolean = false;
  
  @Input() canAccessSadadNumber : boolean = false;
  @Input() canAccessSadadCreationDate : boolean = false;
  @Input() canAccessSadadPayingDate : boolean = false;
  @Input() canAccessBillExpirationDate : boolean = false;
  @Input() canAccessBillAmount : boolean = false;
  @Input() canAccessBillVat : boolean = false;

  @Input() canAccessRequestOwnerRepresentativeName : boolean = false;
  @Input() canAccessRequestOwnerRepresentativeIdentificationNumber : boolean = false;
  @Input() canAccessRequestOwnerRepresentativeExpiryDate : boolean = false;
  @Input() canAccessRequestOwnerRepresentativeTypeText : boolean = false;

  @Input() canAccessRequestOwnerRepresentativeRegion : boolean = false;
  @Input() canAccessRequestOwnerRepresentativeCity : boolean = false;
  @Input() canAccessRequestOwnerRepresentativeStreet : boolean = false;
  @Input() canAccessRequestOwnerRepresentativeBuildingNo : boolean = false;
  @Input() canAccessRequestOwnerRepresentativePostalCode : boolean = false;
  @Input() canAccessRequestOwnerRepresentativeUnitNo : boolean = false;

  @Input() canAccessRequestNumber : boolean = false;
  @Input() canAccessRequestCreationDate : boolean = false;
  @Input() canAccessRequestType : boolean = false;
  @Input() canAccessRequestFormalModificationText : boolean = false;
  @Input() canAccessRequestRealEstateTypeText : boolean = false;
  @Input() canAccessRequestRealEstateRegionName : boolean = false;
  @Input() canAccessRequestRealEstateCityName : boolean = false;
  @Input() canAccessRequestRealEstateAreaMeters : boolean = false;
  @Input() canAccessRequestDocumentTypeText : boolean = false;
  @Input() canAccessRequestDocumentDate : boolean = false;
  // @Input() canAccessRequestOwnerRepresentativeUnitNo : boolean = false;
  @Input() canAccessRequestCourtText : boolean = false;
  @Input() canAccessRequestCaseNo : boolean = false;
  @Input() canAccessRequestCaseDate : boolean = false;
  @Input() canAccessRequestCaseStatusText : boolean = false;
  @Input() canAccessRequestRealEstateLocation : boolean = false;
  @Input() canViewStatementReplyHistoryForAllDept: boolean = false;
  @Input() isEngOffice: boolean = false;

  @Input() requestId!: number;
  @Input() canCreateStatement : boolean = false;
  @Input() canViewStatmentHistory : boolean = false;
  @Input() request?: Request;
  statementType!:StatementType[]
  downloadAttachmentFn: (attachment: IAttachment) => Observable<any>;
  mapLocation?: { lng: number, lat: number };
  isSubmitting = false;
  requestStatus = RequestStatus
  statementResponse!:PagedResultDto<StatementReplyHistoryPerDeptResponse>
  statementReplyHistoryForAllDeptResponse!: StatementReplyHistoryForAllDeptResponse[];
  haveStatementReplyHistoryForAllDept = false
  page = 1;
  pageSize = 3;
  isRequestsStudyingMemberRequests =false
  noteTabType = RequestPreviewTabs.SPGAPublicUser;
  params = new PagedAndSortedResultRequestDto({
    maxResultCount: 3
  }) as StatementReplyHistoryPerDeptRequest;
 // isSecretary: boolean =false;
  isPreliminaryStudiesCommitteeSupervisor: boolean =false;
  isEmirateRepresentative = false;
  isEngOfficeRole = false;
  isPublicUser = false;

  newRequest!:any;
  isLandsModalOpen = false
  isAuditCommitteeSecretary = false;
  currentUser: any;


  constructor(
    private router: Router,
    private repository: RealEstateRequestRepository,
    private attachmentRepository: RealEstateRequestAttachmentRepository,
    private statementRepository: RealEstateRequestStatementsRepository,
    private appAuthService: AppAuthService,
    private toaster: ToasterService,
    private cdr :ChangeDetectorRef
  ) {
    this.downloadAttachmentFn = (attachment: IAttachment) => this.attachmentRepository
      .downloadRealEstateAttachmentById(attachment.id!, attachment.name!);
  }
 
  private captured: boolean = false;
  ngAfterViewChecked() {
    if (this.shadowBeneficiaryDetails && !this.captured) {
      this.captured = true;
      this.captureShadowBeneficiaryDetails();
    }
  }

  captureShadowBeneficiaryDetails() {
    html2canvas(this.shadowBeneficiaryDetails.nativeElement, {
      width: 794,  // A4 width in pixels
      height: 1123 // A4 height in pixels
    }).then(canvas => {
      let imageData = canvas.toDataURL('image/png');

      // Remove the 'data:image/png;base64,' prefix
      imageData = imageData.replace(/^data:image\/png;base64,/, '');
      // Save the image data to Redis
      this.saveToRedis(imageData);
    });
  }


  saveToRedis(imageData: string) {
    this.repository.saveImage('summaryImage' + this.requestId, imageData).subscribe(response => {
      //console.log('Image saved to Redis');
      this.isLoading = false;
    });
  }

  ngOnChanges(): void {
    if (this.canCreateStatement && this.request) {
      this.bindRequest(this.request);
    }
  
  }
  ngOnInit(): void {
    this.currentUser = this.appAuthService.currentLoggedInUser$;
    this.isEngOfficeRole = this.appAuthService.checkIfIsEngineeringOffice();
    this.isPublicUser = this.currentUser.roles[0] == 'SPGAPublicUser';
    this.canAccessRequestBeneficiaryIdentificationNumber = this.appAuthService.checkIfUserHavePermission("SPGA.Request.BeneficiaryData.IdentificationNumber");
    this.canAccessRequestBeneficiaryName = this.appAuthService.checkIfUserHavePermission("SPGA.Request.BeneficiaryData.Name");
    this.canAccessRequesBeneficiaryBirthdate = this.appAuthService.checkIfUserHavePermission("SPGA.Request.BeneficiaryData.Birthdate");
    this.canAccessRequestBeneficiaryEmail = this.appAuthService.checkIfUserHavePermission("SPGA.Request.BeneficiaryData.Email");    
    this.canAccessRequestBeneficiaryMobileNumber = this.appAuthService.checkIfUserHavePermission("SPGA.Request.BeneficiaryData.MobileNumber");
    
    this.canAccessRequestOwnerName = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestOwnerName");
    this.canAccessRequestOwnerNationality = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestOwnerNationality");
    this.canAccessRequestOwnerIdentificationType = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestOwnerIdentificationType");
    this.canAccessRequestOwnerIdentificationNumber = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestOwnerIdentificationNumber");    
    this.canAccessRequestOwnerIssueDate = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestOwnerIssueDate");
    this.canAccessRequestOwnerExpiryDate = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestOwnerExpiryDate");

    this.canAccessSadadNumber = this.appAuthService.checkIfUserHavePermission("SPGA.Request.BillData.SadadNumber");
    this.canAccessSadadCreationDate = this.appAuthService.checkIfUserHavePermission("SPGA.Request.BillData.SadadCreationDate");
    this.canAccessSadadPayingDate = this.appAuthService.checkIfUserHavePermission("SPGA.Request.BillData.SadadPayingDate");
    this.canAccessBillExpirationDate = this.appAuthService.checkIfUserHavePermission("SPGA.Request.BillData.BillExpirationDate");    
    this.canAccessBillAmount = this.appAuthService.checkIfUserHavePermission("SPGA.Request.BillData.BillAmount");
    this.canAccessBillVat = this.appAuthService.checkIfUserHavePermission("SPGA.Request.BillData.BillVat");

    this.canAccessRequestOwnerRepresentativeName = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestOwnerRepresentativeName");
    this.canAccessRequestOwnerRepresentativeIdentificationNumber = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestOwnerRepresentativeIdentificationNumber");
    this.canAccessRequestOwnerRepresentativeExpiryDate = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestOwnerRepresentativeExpiryDate");
    this.canAccessRequestOwnerRepresentativeTypeText = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestOwnerRepresentativeTypeText");    
    

    this.canAccessRequestOwnerRepresentativeRegion = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestOwnerRepresentativeRegion");
    this.canAccessRequestOwnerRepresentativeCity = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestOwnerRepresentativeCity");
    this.canAccessRequestOwnerRepresentativeStreet = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestOwnerRepresentativeStreet");
    this.canAccessRequestOwnerRepresentativeBuildingNo = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestOwnerRepresentativeBuildingNo");    
    this.canAccessRequestOwnerRepresentativePostalCode = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestOwnerRepresentativePostalCode");
    this.canAccessRequestOwnerRepresentativeUnitNo = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestOwnerRepresentativeUnitNo");


    this.canAccessRequestNumber = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestNumber");
    this.canAccessRequestCreationDate = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestCreationDate");
    this.canAccessRequestType = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestType");
    this.canAccessRequestFormalModificationText = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestFormalModificationText");
    this.canAccessRequestRealEstateTypeText = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestRealEstateTypeText");
    this.canAccessRequestRealEstateRegionName = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestRealEstateRegionName");
    this.canAccessRequestRealEstateCityName = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestRealEstateCityName");
    this.canAccessRequestRealEstateAreaMeters = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestRealEstateAreaMeters");
    this.canAccessRequestDocumentTypeText = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestDocumentTypeText");
    this.canAccessRequestDocumentDate = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestDocumentDate");
    // this.canAccessRequestOwnerRepresentativeUnitNo = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestOwnerRepresentativeUnitNo");
    this.canAccessRequestCourtText = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestCourtText");
    this.canAccessRequestCaseNo = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestCaseNo");
    this.canAccessRequestCaseDate = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestCaseDate");
    this.canAccessRequestCaseStatusText = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestCaseStatusText");
    this.canAccessRequestRealEstateLocation = this.appAuthService.checkIfUserHavePermission("SPGA.Request.RequestData.RequestRealEstateLocation");
    
    this.isAuditCommitteeSecretary = this.appAuthService.checkIfUserHavePermission('SPGA.Requests.ApprovedRequests');

      if(this.appAuthService.checkIfUserHavePermission("SPGA.Requests.SaveConsiderationCommittee")){
        this.statementType = [statementTypeOptions.find(x => x.key == ("FromCommitteeSecretaryTo" + this.noteTabType.toString()))?.value || StatementType.FromAuditCommitteeSecretaryToCommitteeSecretary]
      }else{
        this.statementType = [statementTypeOptions.find(x => x.key == ("From" + this.appAuthService.currentLoggedInUser$.roles[0] + "To" + this.noteTabType.toString()))?.value || StatementType.FromAuditCommitteeSecretaryToCommitteeSecretary]
      }
    this.isRequestsStudyingMemberRequests=this.appAuthService.checkIfUserHavePermission('SPGA.Requests.RequestsStudyingMemberRequests');
   // this.isSecretary=this.appAuthService.checkIfUserHavePermission('SPGA.Requests.SaveConsiderationCommittee ');
    this.isPreliminaryStudiesCommitteeSupervisor=this.appAuthService.checkIfUserHavePermission('SPGA.Requests.CompleteRequestAsPreliminaryStudiesCommitteeSupervisor');
    this.isEmirateRepresentative = this.appAuthService.checkIfUserHavePermission('SPGA.Requests.EmirateRepresentativeRequests');
    this.repository.getDetails(this.requestId).subscribe(value => {
      if (value){
        this.bindRequest(value);
        if(value.isChangeEngOffice && !value.isAssignedToNewEngOffice){
          this.canViewStatmentHistory=false
          this.canViewStatementReplyHistoryForAllDept=false
        }

        if (this.canViewStatementReplyHistoryForAllDept) {
          this.statementRepository.getStatementReplyHistoryForAllDept({ requestId: this.requestId, type: this.statementType[0] }).subscribe((data) => {
            this.statementReplyHistoryForAllDeptResponse = data
            this.statementReplyHistoryForAllDeptResponse.forEach(e => {
              if(e.statementReplyHistoryPerDeptResponses.length>0){
                this.haveStatementReplyHistoryForAllDept = true
                return
              }
            });
          })
        }
      if (this.canViewStatmentHistory) {
        this.statementRepository.getStatement({ maxResultCount: 3, requestId: this.requestId, type: this.statementType[0] })
        .subscribe(data => {
          this.statementResponse = data
        })  
      }
      if(value.requestStatus==8 && this.isPreliminaryStudiesCommitteeSupervisor)
      {
        this.isPreWithApprovedRequest = true;
      }
      }
    });
  }

  isAttachmentChanged(){
    if(this.request?.originalRequest && this.request?.realEstateAttachment.length != this.request?.originalRequest.realEstateAttachment.length){
      return true
    }
    if(this.request?.originalRequest){
      for(let i = 0 ; i < this.request?.realEstateAttachment.length ; ++i){
        if(this.request?.realEstateAttachment[i].id != this.request?.originalRequest.realEstateAttachment[i].id){
          return true
        }
      }
    }
    return false
  }
  downloadAndMergeToPdf(): void {
    this.repository.getAttachmentDataForRequestSummary(this.requestId).subscribe(data => { this.toaster.success('تم تنزيل جميع المرفقات بصيغة “PDF” باسم ' + this.requestId +''); });
      //.approve(this.reviewCommitteeRecommendationModel)
      //.subscribe(
      //  () => {
      //    this.router.navigate(['/real-estate-requests', this.auditReqType[2].key]);
      //    this.toaster.success('تم اعتماد البيانات بنجاح');
      //    //  this.resetValues();
      //  },
      //  (error) => {
      //    console.log(error);
      //    // this.resetValues();
      //    return;
      //  }
      //);
  }
  bindRequest(request: Request): void {
    this.request = request
    const point = this.request.realEstate.cordinates[0];
    this.mapLocation = {
      lat: point.latitude,
      lng: point.longitude
    };
    this.cdr.detectChanges()
  }

  acceptRequest(): void {
    this.isSubmitting = true;
    this.repository.accept(this.request?.requestId!)
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(() => this.router.navigateByUrl('/real-estate-requests/requests'));
  }

  pageChange(page: number) {
    this.params.skipCount = (page - 1) * this.params.maxResultCount;
    this.statementRepository.getStatement({maxResultCount:this.params.maxResultCount , requestId:this.requestId , type: this.statementType[0],skipCount:(page - 1) * this.params.maxResultCount}).subscribe(data => this.statementResponse=data)
  }

  isFormalModificationTextChanged(){
    if(this.request?.originalRequest && this.request?.formalModificationText?.length != this.request?.originalRequest.formalModification?.length){
      return true
    }
    if(this.request?.originalRequest){
      for(let i = 0 ; i < this.request?.formalModificationText.length ; ++i){
        if(this.request?.formalModificationText[i] != this.request?.originalRequest.formalModificationText[i]){
          return true
        }
      }
    }
    return false
  }
  isRequestAgencyTypesTextChanged() {
    if (this.request?.originalRequest && this.request?.requestAgencyTypesText?.length != this.request?.originalRequest.requestAgencyTypes?.length) {
      return true
    }
    if (this.request?.originalRequest) {
      for (let i = 0; i < this.request?.requestAgencyTypesText.length; ++i) {
        if (this.request?.requestAgencyTypesText[i] != this.request?.originalRequest.requestAgencyTypesText[i]) {
          return true
        }
      }
    }
    return false
  }
  isRevivalTypeTextChanged(){
    if(this.request?.originalRequest && this.request?.realEstate.revivalTypeText?.length != this.request?.originalRequest.realEstate.revivalTypeText?.length){
      return true
    }
    if(this.request?.originalRequest){
      for(let i = 0 ; i < this.request?.realEstate.revivalTypeText.length ; ++i){
        if(this.request?.realEstate.revivalTypeText[i] != this.request?.originalRequest.realEstate.revivalTypeText[i]){
          return true
        }
      }
    }
    return false
  }
  isRevivalEvidenceTextChanged(){
    if(this.request?.originalRequest && this.request?.realEstate.revivalEvidenceText?.length != this.request?.originalRequest.realEstate.revivalEvidenceText?.length){
      return true
    }
    if(this.request?.originalRequest){
      for(let i = 0 ; i < this.request?.realEstate.revivalEvidenceText.length ; ++i){
        if(this.request?.realEstate.revivalEvidenceText[i] != this.request?.originalRequest.realEstate.revivalEvidenceText[i]){
          return true
        }
      }
    }
    return false
  }
}
